import React from 'react';
import { Text } from 'rebass';
import { titleList } from './css/positions.css';
import CareerPosition from '../../../components/layout/Career/CareerPosition';
import { ListItemSquare } from '../../../components/layout/ListItemComponents/ListItemSquare';

const ContentWriter: React.FC = () => {
  return (
    <CareerPosition positionTitle="Copywriter / Content Writer">
      <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
        Znanja i veštine:
      </Text>
      <ListItemSquare text={'Poznavanje pravopisa i gramatike, VII stepen društveno - humanističkih nauka'} />
      <ListItemSquare text={'Iskustvo u pisanju marketinških tekstova i poruka'} />
      <ListItemSquare text={'Izuzetna pažnja usmerena ka detaljima'} />
      <ListItemSquare text={'Kreativno razmišljanje i samoinicijativnost'} />
      <ListItemSquare text={'Sposobnost lične organizacije i koordinacije radnih zadataka na dnevnom nivou'} />
      <ListItemSquare text={'Efikasnost u radu, proaktivnost, preciznost'} />
      <ListItemSquare text={'Poznavanje engleskog jezika, minimum B2'} />
      <ListItemSquare text={'Minimum 3-4 godina radnog iskustva'} />
      <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
        Opis Poslova
      </Text>
      <ListItemSquare
        text={
          'Kreiranje jasnog i zanimljivog tekstualnog sadržaja za socijalne mreže, blogove, kampanje koje su u skadu sa brendom VOZZi app Kompanije'
        }
      />
      <ListItemSquare text={'Kreiranje i praćenje kalendara objava na socijalnim mrežama'} />
      <ListItemSquare text={'Pisanje članaka, oglasa, newsletter-a, blogova, kao i osmišljavanje marketinških slogana i kampanja'} />
      <ListItemSquare text={'Pisanje SEO sadržaja za website'} />
      <ListItemSquare text={'Kreiranje komunikacionih strategija, izrada planova i strategija za kratkoročne i dugoročne PR kampanje'} />
      <ListItemSquare text={'Kreiranje i skupljanje informacija na online tržištu, marketinška analiza regiona i Evrope'} />
      <ListItemSquare text={'Aktivna saradnja sa članovima marketinga'} />
      <ListItemSquare text={'Kreiranje strategije digitalnog nastupa sa timom marketinga'} />
      <ListItemSquare text={'Za svoj rad odgovara Direktoru marketinga'} />
    </CareerPosition>
  );
};

export default ContentWriter;
